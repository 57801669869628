import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import React, { Component } from "react"
import { FaCalendarAlt } from 'react-icons/fa'
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'
import $ from "jquery"

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlparams: null // Initialize to null
    };
  }

  componentDidMount() {
    // Check if window is available (client side)
    if (typeof window !== "undefined") {
      this.setState({
        urlparams: new URLSearchParams(window.location.search)
      });
    }
  }
  
changeActive(event) {
  $(".form").toggleClass("expanded");
  $("body").toggleClass("formExpanded");
}

mobileActive(event) {
  $(".mobile_navi").toggleClass("expanded");
  $("body").toggleClass("mobileExpanded");
}

render() {
  const { urlparams } = this.state; // Destructure urlparams

  return (
    <>
  <StaticQuery query={ graphql`
          query CompanyQuery {
            sanityCompanyInfo {
              companyname
              companyTagline
              phone
              licenses
              logo{
                asset{
                  fluid{
                    ...GatsbySanityImageFluid
                    src
                  }
                }
              }
              favicon{
                asset{
                  fluid{
                    ...GatsbySanityImageFluid
                    src
                  }
                }
              }
              ctalogo {
                asset {
                  fluid{
                    ...GatsbySanityImageFluid
                    src
                  }
                }
              }
              primarycolor{
                hex
              }
              secondarycolor{
                  hex
              }
              accentcolor{
                  hex
              }
              tertiarycolor{
                hex
              }
              tertiaryaltcolor{
                hex
              }
              analytics
              marchex
              clicky
              remarketing
            }
          }
        `} 

        
        render={data => (
          <>
    <header>
      <div className="mobile_navi">
          <span className="closeMenu" onClick={this.mobileActive}><i class="fal fa-times"></i></span>
          <ul className="mobile-nav-main">
              <li><a href={"/about-us" + (urlparams ? "?" + urlparams : "")}>About Us</a></li>
              <li><a href={"/our-services" + (urlparams ? "?" + urlparams : "")}>Our Services</a></li>
              <li><a href={"/coupons" + (urlparams ? "?" + urlparams : "")}>Offers</a></li>
              <li><a href={"/reviews" + (urlparams ? "?" + urlparams : "")}>Reviews</a></li>
          </ul>
      </div>
      <div className="navi-hero">
          <div className="navi-hero-inner">
            <ul>
              <li><a href={"/about-us" + (urlparams ? "?" + urlparams : "")}>About Us</a></li>
              <li><a href={"/our-services" + (urlparams ? "?" + urlparams : "")}>Our Services</a></li>
              <li><a href={"/coupons" + (urlparams ? "?" + urlparams : "")}>Offers</a></li>
              <li><a href={"/reviews" + (urlparams ? "?" + urlparams : "")}>Reviews</a></li>
            </ul>
          </div>
          <div className="navi-tagline">
            <p style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}><FaMapMarkerAlt/> {data.sanityCompanyInfo.companyTagline}</p>
            <div className="mobileHamburger" onClick={this.mobileActive}>
              <span><i class="far fa-bars"></i></span>
            </div>
          </div>
      </div>
      <div className="header-inner">
        <a href={"/" + (urlparams ? "?" + urlparams : "")}>
        <Image location=""
            fluid={data.sanityCompanyInfo.logo.asset.fluid}
            style={{ height: "auto", width: "200px" }}
            className="align-center"
            alt="Logo"
          />
          </a>
        <div className="headerBtns">
          <div className="btns-wrap">
            <p>CONTACT US!</p>
            <a className="phone phonenumber" href={"tel:" + data.sanityCompanyInfo.phone}><FaPhoneAlt />  <span id="number_rewrite">{data.sanityCompanyInfo.phone}</span></a>
          </div>
        </div>
      </div> 
    </header>  
          </>
        )}
        />
      </>
    )
  }
}



