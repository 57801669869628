import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from "gatsby-image"



function changeActive(){
    $(".form").toggleClass("expanded");
    $('body').toggleClass('formExpanded');
  }

export default () => (
    <StaticQuery query={ graphql`
    query CallToActionQuery($slug: String) {
        site {
          siteMetadata {
            title
          }
        }
        sanityCompanyInfo {
          companyname
          companyTagline
          phone
          licenses
          logo{
            asset{
              fluid{
                ...GatsbySanityImageFluid
                src
              }
            }
          }
          favicon{
            asset{
              fluid{
                ...GatsbySanityImageFluid
                src
              }
            }
          }
          ctalogo {
            asset {
              fluid{
                ...GatsbySanityImageFluid
                src
              }
            }
          }
          primarycolor{
            hex
          }
          secondarycolor{
              hex
          }
          accentcolor{
              hex
          }
          tertiarycolor{
            hex
          }
          tertiaryaltcolor{
            hex
          }
          analytics
          marchex
          clicky
          remarketing
        }
        allSanityBadges{
          edges {
            node {
              badge_img {
                asset {
                  fluid {
                    src
                  }
                }
              }
              badge_name
            }
          }
        }
        sanityPages(slug: {current: {eq: $slug}}) {
          pagetitle
          slug{
            current
          }
          coupon{
            title
            type
          }
          serviceimage{
              asset{
                  fluid(maxWidth: 1920){
                      ...GatsbySanityImageFluid
                      src
                  }
              }
          }
        }
      }
    `}
    
    
    render={ data => (
        <div className="callToAction" style={{ backgroundColor: data.sanityCompanyInfo.tertiarycolor.hex }}>
        <div className="cta-inner">
          <div className="cta-text" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>Call Us Today To Get Started!</div>
          <div className="cta-schedule"><a href="#" onClick={changeActive} style={{ backgroundColor: data.sanityCompanyInfo.secondarycolor.hex, borderColor: data.sanityCompanyInfo.secondarycolor.hex, color: "#fff" }}>Schedule Service</a></div>
          <div className="cta-van">
          <Image location=""
            fluid={data.sanityCompanyInfo.ctalogo.asset.fluid} 
            style={{ height: "auto" }} alt="CTA Logo"
          />
          </div>
        </div>
      </div>
    )}
    />
    )
    












